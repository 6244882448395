// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--89a0a";
export var animateScrollOut = "styles-module--animateScrollOut--1f0cb";
export var colorSequence = "styles-module--colorSequence--911ab";
export var contentContainer = "styles-module--contentContainer--b7be4";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--3a8a1";
export var gradientText = "styles-module--gradientText--87be1";
export var grow = "styles-module--grow--203e6";
export var growAndShrink = "styles-module--growAndShrink--c5ea8";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--7d0af";
export var imageContainer = "styles-module--imageContainer--6e2f8";
export var imgOnRight = "styles-module--imgOnRight--fa682";
export var moveBg = "styles-module--move-bg--cd97c";
export var rotateLoop = "styles-module--rotateLoop--c0933";
export var spin = "styles-module--spin--956d3";
export var spinCounter = "styles-module--spinCounter--6b4d5";
export var twoColMediaContainer = "styles-module--twoColMediaContainer--4a3aa";